import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { ContactComponent } from './components/contact/contact.component';
import { AddressComponent } from './components/delivery/address/address.component';
import { MeterNumberComponent } from './components/delivery/meter-number/meter-number.component';

// Pipes
import { DurationPipe } from './pipes/duration.pipe';
import { CutMinusPipe } from './pipes/cut-minus.pipe';
import { SharedModule } from '../shared/shared.module';
import { ListingPipe } from './pipes/listing.pipe';
import { ZaehlwerktypPipe } from './pipes/zaehlwerktyp.pipe';
import { VerbrauchstypPipe } from './pipes/verbrauchstyp.pipe';
import { DelimiterPipe } from './pipes/delimiter.pipe';
import { MapAbleseartPipe } from './pipes/map-ableseart.pipe';
import { MapAblesegrundPipe } from './pipes/map-ablesegrund.pipe';
import { UnitPrettyPipe } from './pipes/unit-pretty.pipe';
import { MaskIBANPipe } from './pipes/mask-iban.pipe';

// Services
import { BdoApiService } from './services/bdo-api.service';

// Components
import { AnonymousMeterReadingComponent } from './components/anonymous/anonymous-meter-reading/anonymous-meter-reading.component';
import { AnonymousMeterReadingFormComponent } from './components/anonymous/anonymous-meter-reading-form/anonymous-meter-reading-form.component';
import { ZaehlerstandEingabeFormComponent } from './components/zaehlerstand/zaehlerstand-eingabe-form/zaehlerstand-eingabe-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DocumentsTileComponent } from './components/dashboard/documents-tile/documents-tile.component';
import { CustomerTileComponent } from './components/dashboard/customer-tile/customer-tile.component';
import { FrequentFunctionsTileComponent } from './components/dashboard/frequent-functions-tile/frequent-functions-tile.component';
import { BankingTileComponent } from './components/dashboard/banking-tile/banking-tile.component';
import { PaymentTileComponent } from './components/dashboard/payment-tile/payment-tile.component';
import { VerbrauchComponent } from './components/verbrauch/verbrauch.component';
import { DiagrammComponent } from './components/verbrauch/diagramm/diagramm.component';
import { VerbrauchsAnimationComponent } from './components/verbrauchs-animation/verbrauchs-animation.component';
import { MinimalHeaderComponent } from './components/minimal-header/minimal-header.component';
import { MinimalFooterComponent } from './components/minimal-footer/minimal-footer.component';
import { LogoutSuccessComponent } from './components/logout-success/logout-success.component';
import { HistorieComponent } from './components/verbrauch/historie/historie.component';
import { ZaehlerstandHistorieTabelleComponent } from './components/zaehlerstand/zaehlerstand-historie-tabelle/zaehlerstand-historie-tabelle.component';

import { MoreServicesTileComponent } from './components/dashboard/more-services-tile/more-services-tile.component';
import { FeedbackButtonComponent } from './components/feedback/feedback-button/feedback-button.component';
import { FeedbackDialogComponent } from './components/feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackDoneComponent } from './components/feedback/feedback-done/feedback-done.component';
import { AlertTileComponent } from './components/dashboard/alert-tile/alert-tile.component';
import { MaintenanceWarningComponent } from './components/maintenance-warning/maintenance-warning.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { AccountInProgressWarningComponent } from './components/account-in-progress-warning/account-in-progress-warning.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { CampaignPopupComponent } from './components/dashboard/campaign-popup/campaign-popup.component';
import { CampaignTileComponent } from './components/dashboard/campaign-tile/campaign-tile.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { BalanceComponent } from './components/dashboard/banking-tile/balance/balance.component';
import { AssignmentsComponent } from './components/dashboard/banking-tile/assignments/assignments.component';
import { BillingPlanComponent } from './components/billing/billing-plan/billing-plan.component';
import { MeterreadingsComponent } from './components/meterreadings/meterreadings.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BillingEditComponent } from './components/billing/billing-edit/billing-edit.component';
import { ConstructionSiteComponent } from './components/construction-site/construction-site.component';
import { CustomerStatusComponent } from './components/delivery/customer-status/customer-status.component';
import { CustomerDataInitComponent } from './components/customer-data-init/customer-data-init.component';
import { WizardComponent } from './components/delivery/wizard/wizard.component';
import { SituationComponent } from './components/delivery/situation/situation.component';
import { LinkTrackingDirective } from './directives/link-tracking.directive';
import { PersonalDataFormComponent } from './components/delivery/personal-data-form/personal-data-form.component';
import { SituationFormComponent } from './components/delivery/situation-form/situation-form.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { PaymentComponent } from './components/delivery/payment/payment.component';
import { SummaryComponent } from './components/delivery/summary/summary.component';
import { PersonalDataSummaryComponent } from './components/delivery/summary/personal-data-summary/personal-data-summary.component';
import { SituationSummaryComponent } from './components/delivery/summary/situation-summary/situation-summary.component';
import { PaymentsSummaryComponent } from './components/delivery/summary/payments-summary/payments-summary.component';
import { AddressSummaryComponent } from './components/delivery/summary/address-summary/address-summary.component';
import { TariffSummaryComponent } from './components/delivery/summary/tariff-summary/tariff-summary.component';
import { TariffTitlePipe } from './pipes/tariff-title.pipe';
import { ConfirmationComponent } from './components/delivery/confirmation/confirmation.component';
import { DenialComponent } from './components/delivery/denial/denial.component';
import { NewHomeComponent } from './components/move/new-home/new-home.component';
import { MoveSummaryComponent } from './components/move/move-summary/move-summary.component';
import { OldHomeSummaryComponent } from './components/move/move-summary/old-home-summary/old-home-summary.component';
import { NewHomeSummaryComponent } from './components/move/move-summary/new-home-summary/new-home-summary.component';
import { TariffDataSummaryComponent } from './components/move/move-summary/tariff-data-summary/tariff-data-summary.component';
import { LegalSummaryComponent } from './components/delivery/summary/legal-summary/legal-summary.component';
import { TariffAdvisorComponent } from './components/tariff-advisor/tariff-advisor.component';
import { TariffTileComponent } from './components/tariff-tile/tariff-tile.component';
import { ProductSelectionComponent } from './components/move/new-home/product-selection/product-selection.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TariffDetailsComponent } from './components/tariff-details/tariff-details.component';
import { RecentHomeComponent } from './components/move/recent-home/recent-home.component';
import { MoveConfirmationComponent } from './components/move/move-confirmation/move-confirmation.component';
import { TariffAdivsorSummaryComponent } from './components/tariff-advisor/tariff-adivsor-summary/tariff-adivsor-summary.component';
import { MoveStartComponent } from './components/move/move-start/move-start.component';
import { LoginModule } from '../login/login.module';
import { MovePaymentComponent } from './components/move/move-payment/move-payment.component';
import { TariffTotalSummaryComponent } from './components/move/move-summary/tariff-total-summary/tariff-total-summary.component';
import { QuickRegisterFormComponent } from './components/delivery/quick-register-form/quick-register-form.component';
import { TariffsOverviewComponent } from './components/move/move-summary/tariffs-overview/tariffs-overview.component';
import { RecentMeterReadingsComponent } from './components/move/recent-home/recent-meter-readings/recent-meter-readings.component';
import { ExternalTenantTileComponent } from './components/move/move-confirmation/external-tenant-tile/external-tenant-tile.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { PersonalDataFaqsComponent } from './components/personal-data/personal-data-faqs/personal-data-faqs.component';
import { PersonalDataBankingComponent } from './components/personal-data/personal-data-banking/personal-data-banking.component';
import { PersonalDataPasswordComponent } from './components/personal-data/personal-data-password/personal-data-password.component';
import { PersonalDataAddressComponent } from './components/personal-data/personal-data-address/personal-data-address.component';
import { PersonalDataAddressFormComponent } from './components/personal-data/personal-data-address-form/personal-data-address-form.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { PersonalDataPersonalComponent } from './components/personal-data/personal-data-personal/personal-data-personal.component';
import { ProcessNotAvailableComponent } from './components/process-not-available/process-not-available.component';
import { PersonalDataPartnerAddressFormComponent } from './components/personal-data/personal-data-partner-address-form/personal-data-partner-address-form.component';
import { ContractOverviewComponent } from './components/contract-overview/contract-overview.component';
import { ContractDetailsComponent } from './components/contract-overview/contract-details/contract-details.component';
import { TariffOverlayComponent } from './components/tariff-overlay/tariff-overlay.component';
import { DivisionToUnitPipe } from './pipes/division-to-unit.pipe';
import { ContractEditComponent } from './components/contract-edit/contract-edit.component';
import { TariffConsumptionSelectionComponent } from './components/tariff-consumption-selection/tariff-consumption-selection.component';
import { ContractNewAddressComponent } from './components/contract-new/contract-new-address/contract-new-address.component';
import { TariffPricesOverviewComponent } from './components/tariff-prices-overview/tariff-prices-overview.component';
import { ContractEditSummaryComponent } from './components/contract-edit/contract-edit-summary/contract-edit-summary.component';
import { ContractDetailsSummaryComponent } from './components/contract-edit/contract-edit-summary/contract-details-summary/contract-details-summary.component';
import { ContractEditConfirmationComponent } from './components/contract-edit/contract-edit-confirmation/contract-edit-confirmation.component';
import { ContractNewProductsComponent } from './components/contract-new/contract-new-products/contract-new-products.component';
import { SupplierAutosuggestComponent } from './components/supplier-autosuggest/supplier-autosuggest.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { ContractNewPaymentComponent } from './components/contract-new/contract-new-payment/contract-new-payment.component';
import { ContractEndRecentHomeComponent } from './components/contract-end/contract-end-recent-home/contract-end-recent-home.component';
import { ContractEndPaymentComponent } from './components/contract-end/contract-end-payment/contract-end-payment.component';
import { BillingAddressComponent } from './components/contract-end/billing-address/billing-address.component';
import { ContractNewTariffsComponent } from './components/contract-new/contract-new-tariffs/contract-new-tariffs.component';
import { ContractEndSummaryComponent } from './components/contract-end/contract-end-summary/contract-end-summary.component';
import { BillingAddressSummaryComponent } from './components/contract-end/contract-end-summary/billing-address-summary/billing-address-summary.component';
import { DeferPaymentComponent } from './components/payments/defer-payment/defer-payment.component';
import { ContractNewSummaryComponent } from './components/contract-new/contract-new-summary/contract-new-summary.component';
import { ContractNewConfirmationComponent } from './components/contract-new/contract-new-confirmation/contract-new-confirmation.component';
import { SiminvoiceOverviewComponent } from './components/siminvoice/siminvoice-overview/siminvoice-overview.component';
import { SiminvoiceMeterReadingsComponent } from './components/siminvoice/siminvoice-meter-readings/siminvoice-meter-readings.component';
import { ContractCancelAuthComponent } from './components/contract-cancel/contract-cancel-auth/contract-cancel-auth.component';
import { RouterModule } from '@angular/router';
import { ContractCancelSelectionComponent } from './components/contract-cancel/contract-cancel-selection/contract-cancel-selection.component';
import { ContractEndConfirmationComponent } from './components/contract-end/contract-end-confirmation/contract-end-confirmation.component';
import { ContractCancelAddressComponent } from './components/contract-cancel/contract-cancel-address/contract-cancel-address.component';
import { ContractCancelDateComponent } from './components/contract-cancel/contract-cancel-date/contract-cancel-date.component';
import { ContractCancelConfirmationComponent } from './components/contract-cancel/contract-cancel-confirmation/contract-cancel-confirmation.component';
import { ContractCancelSummaryComponent } from './components/contract-cancel/contract-cancel-summary/contract-cancel-summary.component';
import { ContractCancelOverviewComponent } from './components/contract-cancel/contract-cancel-summary/contract-cancel-overview/contract-cancel-overview.component';
import { ContractCancelStatusComponent } from './components/contract-cancel-status/contract-cancel-status.component';
import { PersonalDataOptinComponent } from './components/personal-data/personal-data-optin/personal-data-optin.component';
import { ContractEditAuthComponent } from './components/contract-edit/contract-edit-auth/contract-edit-auth.component';
import { ContractDetailsTileComponent } from './components/contract-overview/contract-details-tile/contract-details-tile.component';
import { TariffPricesCostInfoComponent } from './components/tariff-prices-cost-info/tariff-prices-cost-info.component';
import { NetcologneTeaserComponent } from './components/netcologne-teaser/netcologne-teaser.component';
import { OfferStatusComponent } from './components/offer/offer-status/offer-status.component';
import { OfferTariffAdvisorComponent } from './components/offer/offer-tariff-advisor/offer-tariff-advisor.component';
import { ContractDetailsSmallTileComponent } from './components/contract-overview/contract-details-small-tile/contract-details-small-tile.component';
import { ContractDetailsTariffInfoComponent } from './components/contract-overview/contract-details-tariff-info/contract-details-tariff-info.component';
import { TariffPriceCapComponent } from './components/tariff-price-cap/tariff-price-cap.component';
import { VeboutExistingComponent } from './components/vebout-existing/vebout-existing.component';
import { TariffSelectionComponent } from './components/tariff-selection/tariff-selection.component';
import { HotlineSlideOverComponent } from './components/hotline-slide-over/hotline-slide-over.component';
import { ContractEndStatusComponent } from './components/contract-end/contract-end-status/contract-end-status.component';
import { PersonalDataBankingFormComponent } from './components/personal-data/personal-data-banking-form/personal-data-banking-form.component';
import { BillingConsumptionTileComponent } from './components/dashboard/billing-consumption-tile/billing-consumption-tile.component';
import { BillingEditDivisionComponent } from './components/billing/billing-edit/billing-edit-division/billing-edit-division.component';
import { OnlineServiceRemovedMessageComponent } from './components/online-service-removed-message/online-service-removed-message.component';
import { COMPETITION_CONFIGURATION_TOKEN } from './configurations/competition.configuration.token';
import { activeCompetition } from './configurations/competition.configuration';
import { PRICE_CAP_TOKEN, PRICE_CAP_VALID_UNTIL } from './configurations/price-cap.configuration.token';
import { priceCapValidUntil, priceCapValues } from './configurations/price-cap.configuration';
import { READONLY_TENANTS_CONFIGURATION_TOKEN } from './configurations/readonly-tenants.configuration.token';
import { readonlyTenantsConfiguration } from './configurations/readonly-tenants.configuration';
import { WishvoucherComponent } from './components/wishvoucher/wishvoucher.component';
import { OptInPopupComponent } from './components/dashboard/opt-in-popup/opt-in-popup/opt-in-popup.component';
import { EffzehStromTeaserComponent } from './components/effzeh-strom-teaser/effzeh-strom-teaser.component';

@NgModule({ declarations: [
        AddressComponent,
        AnonymousMeterReadingFormComponent,
        AnonymousMeterReadingComponent,
        ContactComponent,
        DurationPipe,
        CutMinusPipe,
        ListingPipe,
        ZaehlwerktypPipe,
        VerbrauchstypPipe,
        DelimiterPipe,
        VerbrauchComponent,
        DiagrammComponent,
        ZaehlerstandEingabeFormComponent,

        DashboardComponent,
        DocumentsTileComponent,
        CustomerTileComponent,
        FrequentFunctionsTileComponent,
        BankingTileComponent,
        PaymentTileComponent,
        VerbrauchsAnimationComponent,
        MinimalHeaderComponent,
        LogoutSuccessComponent,
        MapAbleseartPipe,
        MapAblesegrundPipe,
        HistorieComponent,
        UnitPrettyPipe,
        ZaehlerstandHistorieTabelleComponent,
        MoreServicesTileComponent,
        OptInPopupComponent,
        MinimalFooterComponent,
        FeedbackButtonComponent,
        FeedbackDialogComponent,
        FeedbackDoneComponent,
        AlertTileComponent,
        MaintenanceWarningComponent,
        TermsOfServiceComponent,
        AccountInProgressWarningComponent,
        DocumentsComponent,
        PaymentsComponent,
        CampaignPopupComponent,
        CampaignTileComponent,
        MaskIBANPipe,
        BalanceComponent,
        AssignmentsComponent,
        BillingPlanComponent,
        MeterreadingsComponent,
        BreadcrumbComponent,
        BillingEditComponent,
        ConstructionSiteComponent,
        CustomerStatusComponent,
        CustomerDataInitComponent,
        WizardComponent,
        SituationComponent,
        LinkTrackingDirective,
        PersonalDataFormComponent,
        SituationFormComponent,
        MeterNumberComponent,
        SituationFormComponent,
        PaymentComponent,
        SummaryComponent,
        SituationSummaryComponent,
        PersonalDataSummaryComponent,
        PaymentsSummaryComponent,
        AddressSummaryComponent,
        TariffSummaryComponent,
        TariffTitlePipe,
        ConfirmationComponent,
        DenialComponent,
        NewHomeComponent,
        MoveSummaryComponent,
        TariffsOverviewComponent,
        OldHomeSummaryComponent,
        NewHomeSummaryComponent,
        TariffDataSummaryComponent,
        LegalSummaryComponent,
        TariffAdvisorComponent,
        TariffTileComponent,
        ProductSelectionComponent,
        TariffDetailsComponent,
        RecentHomeComponent,
        MoveConfirmationComponent,
        TariffAdivsorSummaryComponent,
        MoveStartComponent,
        MovePaymentComponent,
        TariffTotalSummaryComponent,
        QuickRegisterFormComponent,
        RecentMeterReadingsComponent,
        ExternalTenantTileComponent,
        PersonalDataComponent,
        PersonalDataFaqsComponent,
        PersonalDataBankingComponent,
        PersonalDataPasswordComponent,
        PersonalDataAddressComponent,
        PersonalDataAddressFormComponent,
        AddressFormComponent,
        PersonalDataPersonalComponent,
        ProcessNotAvailableComponent,
        PersonalDataPartnerAddressFormComponent,
        ContractOverviewComponent,
        ContractDetailsComponent,
        TariffOverlayComponent,
        DivisionToUnitPipe,
        ContractEditComponent,
        TariffConsumptionSelectionComponent,
        ContractNewAddressComponent,
        TariffPricesOverviewComponent,
        ContractEditSummaryComponent,
        ContractDetailsSummaryComponent,
        ContractEditConfirmationComponent,
        ContractNewProductsComponent,
        SupplierAutosuggestComponent,
        PaymentFormComponent,
        ContractNewPaymentComponent,
        ContractEndRecentHomeComponent,
        ContractEndPaymentComponent,
        BillingAddressComponent,
        ContractNewTariffsComponent,
        ContractEndSummaryComponent,
        BillingAddressSummaryComponent,
        ContractEndConfirmationComponent,
        ContractNewSummaryComponent,
        ContractNewConfirmationComponent,
        TariffConsumptionSelectionComponent,
        TariffConsumptionSelectionComponent,
        SiminvoiceOverviewComponent,
        SiminvoiceMeterReadingsComponent,
        ContractCancelAuthComponent,
        ContractCancelSelectionComponent,
        DeferPaymentComponent,
        ContractCancelDateComponent,
        ContractCancelAddressComponent,
        ContractCancelConfirmationComponent,
        ContractCancelSummaryComponent,
        ContractCancelOverviewComponent,
        ContractCancelStatusComponent,
        PersonalDataOptinComponent,
        ContractEditAuthComponent,
        ContractDetailsTileComponent,
        TariffPricesCostInfoComponent,
        NetcologneTeaserComponent,
        OfferStatusComponent,
        OfferTariffAdvisorComponent,
        ContractDetailsSmallTileComponent,
        ContractDetailsTariffInfoComponent,
        TariffPriceCapComponent,
        VeboutExistingComponent,
        TariffSelectionComponent,
        HotlineSlideOverComponent,
        ContractEndStatusComponent,
        PersonalDataBankingFormComponent,
        BillingConsumptionTileComponent,
        BillingEditDivisionComponent,
        OnlineServiceRemovedMessageComponent,
        WishvoucherComponent,
        EffzehStromTeaserComponent
    ],
    exports: [
        MinimalHeaderComponent,
        MinimalFooterComponent,
        FeedbackButtonComponent,
        FeedbackDialogComponent,
        FeedbackDoneComponent,
        MaintenanceWarningComponent,
        CampaignPopupComponent,
        OnlineServiceRemovedMessageComponent,
        CampaignTileComponent,
        BreadcrumbComponent,
        TariffTileComponent,
        OptInPopupComponent,
        LinkTrackingDirective,
        HotlineSlideOverComponent
    ], imports: [BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        SharedModule,
        NgChartsModule,
        NgxTippyModule,
        LoginModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe], providers: [
        BdoApiService,
        VerbrauchstypPipe,
        DatePipe,
        UnitPrettyPipe,
        ListingPipe,
        TariffTitlePipe,
        DivisionToUnitPipe,
        provideNgxMask(),
        { provide: COMPETITION_CONFIGURATION_TOKEN, useValue: activeCompetition },
        { provide: PRICE_CAP_TOKEN, useValue: priceCapValues },
        { provide: PRICE_CAP_VALID_UNTIL, useValue: priceCapValidUntil },
        { provide: READONLY_TENANTS_CONFIGURATION_TOKEN, useValue: readonlyTenantsConfiguration },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class BDOModule {
}
