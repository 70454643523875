<div *ngIf="selectedZaehler?.isSmartMeter else form" class="re-form-item-container">
  {{ 'meterreadings.smartmeter.readingInfo' | translate }}
  <button class="bdo-nolink re-block re-mt-30" type="button" (click)="closeMessage()">
    <div class="bold"> {{ 'general.action.cancel' | translate }} </div>
  </button>
</div>
<ng-template #form>
  <div *ngIf="meterreading?.zaehlwerkeListe?.length > 1" class="zaehler-select__hint re-mb-10 re-container__content">
    {{ 'meterreadings.input.hintMoreRegister' | translate }}
  </div>

  <div class="bdo-container--single re-mt-10" *ngIf="saveMeterError">
    <bdo-info-box *ngIf="saveMeterError === SaveMeterError.METER_ALREADY_BILLED">
      {{ 'meterreadings.error.meterAlreadyBilled' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngIf="saveMeterError === SaveMeterError.METERREADING_EXISTS" [type]="'warning'">
      {{ 'meterreadings.error.meterreadingExists' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngIf="saveMeterError === SaveMeterError.NO_ACTIVE_CONTRACT">
      {{ 'meterreadings.error.meterBeforeMovein' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngIf="saveMeterError === SaveMeterError.DEFAULT">
      {{ 'meterreadings.error.general' | translate }}
    </bdo-info-box>
  </div>

  <form class="re-relative" (ngSubmit)="save()" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="readingForm">
    <div class="bdo-loading__wrapper">
      <fieldset class="re-mb-0">
        <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
        <div *ngIf="zaehleridentifiers?.length>0">
          <div class="bdo-form-item-container">
            <bdo-date-picker *ngIf="meterreading"
              [labelText]="'meterreadings.info.meterreadingDate' | translate"
              [minDateForChanges]="meterreading?.minDateForChanges"
              [maxDateForChanges]="meterreading?.maxDateForChanges"
              [selectedDate]="selectedDate"
              [tooltipContent]="'meterreadings.edit.description' | translate"
              [attr.data-qa_mindateforchanges]="meterreading?.minDateForChanges"
              (focus)="resetData(false)"
              (dateSelected)="onDateSelected($event)">
            </bdo-date-picker>

            <bdo-info-box *ngIf="readingExists" [type]="'warning'">
              {{ 'meterreadings.error.dateAlreadyRegistered' | translate }}
            </bdo-info-box>
          </div>

          <div *ngFor="let zaehlwerk of meterreading?.zaehlwerkeListe"
            class="meter-content__meterData bdo-form-item-container">
            <bdo-input #meterInput
              [attr.data-qa]="'meterreadingInput-' + zaehlwerk.zaehlwerknummer"
              [validationIconHidden]="validationIconHidden"
              [formControl]="$any(readingForm?.get('reading-' + zaehlwerk.zaehlwerknummer))"
              placeholder="{{ 'meterreadings.meterreadingIn' | translate }} {{zaehlwerk.einheit | unitPretty}} {{ '' | zaehlwerktyp: zaehlwerk.typ }}"
              [tooltipContent]="meterreadingInfoService.getInfo(selectedZaehler.sparte)" (focus)="resetData(false)">
            </bdo-input>
          </div>

          <div *ngIf="implausible">
            <bdo-info-box [type]="'warning'">
              {{ 'meterreadings.input.confirmMeterreading' | translate }}
            </bdo-info-box>

            <div class="bdo-form-item-container">
              <bdo-checkbox
                [required]="true"
                [checked]="checked"
                (change)="checked=!checked">
                {{ 'meterreadings.input.confirmation' | translate }}
              </bdo-checkbox>
            </div>
          </div>
          <button class="bdo-button-primary re-mb-30" type="submit" [disabled]="state === LoadingState.LOADING">
            {{ 'general.action.save' | translate }}
          </button>
        </div>
        <div *ngIf="!zaehleridentifiers || zaehleridentifiers?.length===0" class="re-mb-30">
          {{ 'general.error.noValues' | translate }}
        </div>
        <button class="bdo-nolink" type="button" (click)="done.emit(true)">
          <div class="bold"> {{ 'general.action.cancel' | translate }} </div>
        </button>
      </fieldset>
    </div>
  </form>
</ng-template>
